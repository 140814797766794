import { extend, localize } from 'vee-validate';
import {
  required as ruleRequired,
  email as ruleEmail,
  min as ruleMin,
  max as ruleMax,
  min_value as ruleMinValue,
  max_value as ruleMaxValue,
  confirmed as ruleConfirmed,
  regex as ruleRegex,
  between as ruleBetween,
  alpha as ruleAlpha,
  integer as ruleInteger,
  digits as ruleDigits,
  alpha_dash as ruleAlphaDash,
  alpha_num as ruleAlphaNum,
  length as ruleLength,
} from 'vee-validate/dist/rules';
import es from 'vee-validate/dist/locale/es.json';
import ca from 'vee-validate/dist/locale/ca.json';
import en from 'vee-validate/dist/locale/en.json';

import {
  validatorPositive,
  validatorUrlValidator,
  validatorPhoneValidator,
  validatorUrlYoutube,
  validatorPassword,
  validatorCreditCard,
  validatorNifValidator,
  validatorDniValidator,
  validatorEmailValidator,
  validatorWithoutSpecialCharacters,
} from './validators';

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', ruleRequired);

export const email = extend('email', ruleEmail);

export const min = extend('min', ruleMin);

export const max = extend('max', ruleMax);

export const minValue = extend('min_value', ruleMinValue);

export const maxValue = extend('max_value', ruleMaxValue);

export const confirmed = extend('confirmed', ruleConfirmed);

export const regex = extend('regex', ruleRegex);

export const between = extend('between', ruleBetween);

export const alpha = extend('alpha', ruleAlpha);

export const integer = extend('integer', ruleInteger);

export const digits = extend('digits', ruleDigits);

export const alphaDash = extend('alpha-dash', ruleAlphaDash);

export const alphaNum = extend('alpha-num', ruleAlphaNum);

export const length = extend('length', ruleLength);

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
});

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
});

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Must contain at least one uppercase, one lowercase, one special character and one digit',
});

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
});

export const email2 = extend('email', {
  validate: validatorEmailValidator,
  message: 'Email is invalid',
});
export const phone = extend('phone', {
  validate: validatorPhoneValidator,
  message: 'Phone is invalid',
});
export const youtubeURL = extend('youtubeURL', {
  validate: validatorUrlYoutube,
  message: 'URL is invalid youtube address',
});

export const nif = extend('nif', {
  validate: validatorNifValidator,
  message: 'NIF is invalid',
});
export const dni = extend('dni', {
  validate: validatorDniValidator,
  message: 'DNI is invalid',
});
export const slug = extend('slug', {
  validate: validatorWithoutSpecialCharacters,
  message: 'Slug is invalid',
});
export const greaterThan = extend('greaterThan', {
  params: ['target'],
  validate(value, { target }) {
    return value > target;
  },
  message: 'The {_field_} must be greater than {target}',
});

// Install English and Arabic localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
  es: {
    messages: es.messages,
  },
  ca: {
    messages: ca.messages,
  },
});
// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
